export default function handleStyleImageMissing(event) {
  // add an empty image to prevent icon missing warnings
  const { id, target: map } = event
  const size = 0
  const bytesPerPixel = 4 // Each pixel is represented by 4 bytes: red, green, blue, and alpha.
  const data = new Uint8Array(size * size * bytesPerPixel)
  for (let x = 0; x < size; x += 1) {
    for (let y = 0; y < size; y += 1) {
      const offset = (y * size + x) * bytesPerPixel
      data[offset + 0] = 0 // red
      data[offset + 1] = 0 // green
      data[offset + 2] = 0 // blue
      data[offset + 3] = 255 // alpha
    }
  }
  map.addImage(id, { width: size, height: size, data })
}
