/*
https://codesandbox.io/s/5riij?file=/src/App.tsx:1828-1837

try setting touch-action: none; in the css.
https://use-gesture.netlify.app/docs/extras/

https://codesandbox.io/s/5riij

https://codesandbox.io/s/inertia-fake-scroll-wjz5s

https://use-gesture.netlify.app/docs/utilities/

https://use-gesture.netlify.app/docs/state/

*/
import { useGesture } from "@use-gesture/react"
import { useState, useContext, useRef } from "react"
import ZoomContext from "../comps/ZoomContext"
import { clientToSvg, correctClientPoint } from "../util/geometry"

const pinchIdleTime = 100

export default function useTouchZoom({
  view,
  setView,
  constrainView,
  clientSize,
}) {
  const dragVelocity = useRef(null)
  const zoom = useContext(ZoomContext)
  const [initialPinch, setInitialPinch] = useState(null)

  const dragMomentum = () => {
    if (!dragVelocity.current) {
      return
    }
    const { vx, vy, time: lastTime } = dragVelocity.current

    const time = Date.now()
    const deltaTime = time - lastTime

    const friction = deltaTime * 0.03
    const speed = Math.sqrt(vx * vx + vy * vy) - friction
    const angle = Math.atan2(vy, vx)
    dragVelocity.current.vx = Math.cos(angle) * speed
    dragVelocity.current.vy = Math.sin(angle) * speed
    dragVelocity.current.time = time

    setView((lastView) =>
      constrainView({
        x: lastView.x + vx,
        y: lastView.y + vy,
        scale: lastView.scale,
      })
    )

    if (speed > 0) {
      requestAnimationFrame(dragMomentum)
    } else {
      dragVelocity.current = null
    }
  }

  const handleDrag = ({ delta: [mx, my], last }) => {
    if (last) {
      // console.log("drag.last")
      requestAnimationFrame(dragMomentum)
      return
    }

    if (initialPinch) {
      return
    }

    let vx = -mx
    let vy = -my
    if (Math.abs(vx) > Math.abs(vy)) {
      vy = 0
    } else {
      vx = 0
    }

    setView((lastView) => {
      dragVelocity.current = {
        vx,
        vy,
        time: Date.now(),
      }
      return constrainView({
        ...lastView,
        x: lastView.x - mx / lastView.scale / zoom,
        y: lastView.y - my / lastView.scale / zoom,
      })
    })
  }

  const handlePinch = (state) => {
    const {
      da: [distance],
      origin: [ox, oy],
      first,
      last,
    } = state

    if (first) {
      setInitialPinch({ distance, view })
      return
    }

    if (!initialPinch) {
      return
    }

    setView((lastView) => {
      const point = correctClientPoint({ x: ox, y: oy }, clientSize, zoom)
      const nextView = constrainView({
        ...lastView,
        scale: initialPinch.view.scale * (distance / initialPinch.distance),
      })

      const lastPivot = clientToSvg(point, lastView)
      const nextPivot = clientToSvg(point, {
        ...lastView,
        scale: nextView.scale,
      })
      return constrainView({
        x: lastView.x - (nextPivot.x - lastPivot.x),
        y: lastView.y - (nextPivot.y - lastPivot.y),
        scale: nextView.scale,
      })
    })

    if (last) {
      setTimeout(() => setInitialPinch(null), pinchIdleTime)
    }
  }

  return useGesture(
    {
      onDrag: handleDrag,
      onPinch: handlePinch,
    },
    {
      eventOptions: { passive: false, filterTaps: true, preventScroll: true },
    }
  )
}
