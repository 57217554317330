import { useEffect, useMemo, useState } from "react"
import { useInterval, useKey } from "react-use"
import useGlobalState from "../hooks/useGlobalState"
import useLocationStatistic from "../hooks/useLocationStatistic"
import useServerOnline from "../hooks/useServerOnline"
import {
  makeRequest,
  urlToFilename,
  wordpressCategoriesUrl,
  wordpressHost,
  wordpressMediaUrl,
  wordpressMenuUrl,
  wordpressPostsUrl,
} from "../urls"
import { parseStructure } from "../util/menu"

const filterPostsForOfflineUse = (posts) => {
  const offlinePosts = posts.filter(
    (post) => post.acf.type !== "website" && post.acf.type !== "video"
  )
  return offlinePosts
}

const reloadTime = 60 * 60 * 1000 // 1 hour

export default function Loader({ children }) {
  useLocationStatistic()
  const [time, setTime] = useState(0)
  const { state, setState } = useGlobalState()
  const { online } = state

  const [value, setValue] = useState(null)
  const request = useMemo(() => makeRequest(wordpressHost), [wordpressHost])
  const nextOnline = useServerOnline(request)

  useInterval(() => {
    setTime(time + 1)
  }, reloadTime)

  useEffect(() => {
    console.log("online state change network: ", nextOnline)
    setState({ online: nextOnline })
  }, [nextOnline])

  useKey("o", () => {
    console.log("online state change manual")
    setState((lastState) => ({
      online: !lastState.online,
    }))
  })

  useKey("l", () => {
    console.log("lang state change manual")
    setState((lastState) => ({
      lang: lastState.lang === "de" ? "en" : "de",
    }))
  })

  useEffect(() => {
    let controller = new AbortController()
    ;(async () => {
      console.log("online state change effect: ", online)
      let urls = [
        wordpressMenuUrl,
        wordpressCategoriesUrl,
        wordpressPostsUrl,
        wordpressMediaUrl,
      ]
      if (!online) {
        urls = urls.map((url) => urlToFilename(url, ".json"))
      } else {
        urls = urls.map(makeRequest)
      }
      let nextValue = null
      try {
        nextValue = await Promise.all(
          urls.map(async (url) => {
            const response = await fetch(url, {
              signal: controller.signal,
            })
            const result = await response.json()
            return result
          })
        )
      } catch (error) {
        console.log("fetch error: ", error)
      }
      setValue(nextValue)
      controller = null
    })()
    return () => controller?.abort()
  }, [online, time])

  useEffect(() => {
    if (!value) {
      return
    }
    const [menu, categories, unfilteredPosts, media] = value
    const posts = online
      ? unfilteredPosts
      : filterPostsForOfflineUse(unfilteredPosts)
    const root = parseStructure(menu, categories, posts)
    setState({
      root,
      menu,
      categories,
      posts,
      media,
    })
  }, [value, setState])

  const loaded = !!(state.menu && state.categories && state.posts)

  if (!loaded) {
    return null
  }

  return children
}
