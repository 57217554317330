import { useContext } from "react"
import useGlobalState from "../../hooks/useGlobalState"
import Raw from "../Raw"
import HistoryTrailSkipButton from "./HistoryTrailSkipButton"
import MapContext from "./MapContext"

export default function Popup({ title, description, link, historyTrailIndex }) {
  const {
    state: { enlarged },
  } = useGlobalState()

  const { setBrowser } = useContext(MapContext)

  const handleClickLink = () => {
    setBrowser({ title, link })
  }

  return (
    <div className={enlarged ? "enlarged" : ""}>
      <p>
        <strong>{title}</strong>
      </p>
      <p>
        <Raw>{description}</Raw>
      </p>
      {historyTrailIndex && (
        <>
          <HistoryTrailSkipButton delta={-1} index={historyTrailIndex} />
          <HistoryTrailSkipButton delta={+1} index={historyTrailIndex} />
        </>
      )}
      {link ? (
        <p>
          <a onClick={handleClickLink}>Mehr Informationen</a>
        </p>
      ) : null}
    </div>
  )
}
