import useTranslation from "../../hooks/useTranslation"

export default function Intro({ category: untranslatedCategory, children }) {
  const category = useTranslation(untranslatedCategory)
  const { description } = category
  return (
    <div className="intro">
      <p>{description}</p>
      {children}
    </div>
  )
}
