import useGlobalState from "./useGlobalState"

export default function useMedia(id) {
  const {
    state: { media },
  } = useGlobalState()

  if (!id) {
    return null
  }

  const mediaObject = media.find((item) => item.id === id)
  if (!mediaObject) {
    throw new Error(`Media with id ${id} not found`)
  }
  return mediaObject
}
