import { useMemo } from "react"
import { useParams } from "react-router-dom"
import useGlobalState from "../../hooks/useGlobalState"
import useTranslation from "../../hooks/useTranslation"
import CloseButton from "../CloseButton"
import ErrorMessage from "../ErrorMessage"
import styles from "./Post.module.scss"
import * as components from "./types"

const defaultIndex = 0

const findRootIndex = (root, post, menu) => {
  if (!post) {
    return defaultIndex
  }

  const [categoryId] = post.categories
  const menuItem = menu.find(
    (item) =>
      item.object === "category" &&
      String(item.object_id) === String(categoryId)
  )
  const findPath = (path, childItem) => {
    const parentId = childItem.menu_item_parent
    if (!parentId) {
      return path
    }
    const parentItem = menu.find((item) => String(item.ID) === String(parentId))
    if (parentItem) {
      return findPath([parentItem, ...path], parentItem)
    }
    return path
  }

  if (!menuItem) {
    return defaultIndex
  }

  const path = findPath([], menuItem)
  const sectionItem = path[1]
  if (!sectionItem) {
    return defaultIndex
  }
  return root.children.findIndex(
    (item) => String(item.id) === String(sectionItem.object_id)
  )
}

function PostInner({ post }) {
  const {
    acf: { type },
  } = post

  const Component = components[type]
  if (!Component) {
    return <ErrorMessage>Invalid post type: {type}</ErrorMessage>
  }

  return <Component post={post} />
}

export default function Post() {
  const { slug } = useParams()
  const {
    state: { posts, root, menu },
  } = useGlobalState()

  const germanPost = posts.find((item) => item.slug === slug)

  const rootIndex = useMemo(
    () => findRootIndex(root, germanPost, menu),
    [root, germanPost, menu]
  )

  if (!germanPost) {
    throw new Error("Post not found")
  }

  const translatedPost = useTranslation(germanPost)

  const {
    acf: { type },
  } = translatedPost

  return (
    <div className={styles.post}>
      <div
        className={`post-content post-content-${type} submenu-index-${rootIndex}`}
      >
        <PostInner post={translatedPost} />
        <CloseButton />
      </div>
    </div>
  )
}
