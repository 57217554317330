import { forwardRef } from "react"
import useObjectUrl from "../hooks/useObjectUrl"

const Image = forwardRef(({ src, as = "img", ...props }, ref) => {
  const result = useObjectUrl(src)
  if (!result) {
    return <div className="img-placeholder" />
  }

  if (as === "image") {
    return <image {...props} ref={ref} href={result} />
  }

  return <img {...props} ref={ref} src={result} />
})

export default Image
