import { useContext, useMemo } from "react"
import MapContext from "./MapContext"

export default function HistoryTrailSkipButton({ delta, index }) {
  const { setCenteredMarkerId, markers } = useContext(MapContext)
  const nextIndex = parseInt(index, 10) + delta
  const nextPlace = useMemo(
    () =>
      markers.find(
        (marker) => parseInt(marker.historyTrailIndex, 10) === nextIndex
      ),
    [markers, nextIndex]
  )

  if (!nextPlace) {
    return null
  }

  const handleClick = () => setCenteredMarkerId(nextPlace.id)

  return (
    <a
      onClick={handleClick}
      className={`skip-button ${delta === -1 ? "prev" : "next"}`}
    />
  )
}
