import Media, { sizes } from "../../../Media"

export default function ColumnGallerySide({ images, setImage, image }) {
  return (
    <div className="column-side column-side-gallery">
      {images.map((id) => (
        <div
          key={id}
          to={`./image/${id}`}
          className={`thumbnail ${id === image ? "active" : ""}`}
          onClick={() => setImage(id)}
        >
          <Media id={id} size={sizes.medium} />
        </div>
      ))}
    </div>
  )
}
