export const clamp = (number, min, max) => Math.max(min, Math.min(number, max))

export const boundsToRect = (bounds) => ({
  x: bounds.left,
  y: bounds.top,
  width: bounds.right - bounds.left,
  height: bounds.bottom - bounds.top,
})

export const rectToBounds = (rect) => ({
  left: rect.x,
  top: rect.y,
  right: rect.x + rect.width,
  bottom: rect.y + rect.height,
})

export const rectContainsOtherRect = (outerRect, innerRect) =>
  outerRect.x <= innerRect.x &&
  outerRect.x + outerRect.width >= innerRect.x + innerRect.width &&
  outerRect.y <= innerRect.y &&
  outerRect.y + outerRect.height >= innerRect.y + innerRect.height

export const rectContainsOrIntersectsOtherRect = (outerRect, innerRect) => {
  if (outerRect.x > innerRect.x + innerRect.width) return false
  if (outerRect.x + outerRect.width < innerRect.x) return false
  if (outerRect.y > innerRect.y + innerRect.height) return false
  if (outerRect.y + outerRect.height < innerRect.y) return false
  return true
}

export const mergeRects = (rects) => {
  const bounds = rects.map(rectToBounds)
  const left = Math.min(...bounds.map((bound) => bound.left))
  const top = Math.min(...bounds.map((bound) => bound.top))
  const right = Math.max(...bounds.map((bound) => bound.right))
  const bottom = Math.max(...bounds.map((bound) => bound.bottom))
  return boundsToRect({ left, top, right, bottom })
}

export const fitIntoClient = (rect, viewportRef) => {
  const target = viewportRef.current.getBoundingClientRect()
  const { clientWidth, clientHeight } = document.documentElement
  const client = { width: clientWidth, height: clientHeight }
  const zoom = Math.min(target.width / rect.width, target.height / rect.height)
  const nextRect = {
    x: rect.x + (rect.width - target.width / zoom) / 2 - target.left / zoom,
    y: rect.y + (rect.height - target.height / zoom) / 2 - target.top / zoom,
    width: client.width / zoom,
    height: client.height / zoom,
  }

  return nextRect
}

export function rectsIntersect(rect1, rect2) {
  return !(
    rect2.x > rect1.x + rect1.width ||
    rect2.x + rect2.width < rect1.x ||
    rect2.y > rect1.y + rect1.height ||
    rect2.y + rect2.height < rect1.y
  )
}

export function vectorToAngle({ x, y }) {
  return (Math.atan2(y, x) * 180) / Math.PI
}

export function axisAlignedBounds(points) {
  if (points.length === 0) {
    return null
  }
  const xs = points.map((p) => p.x)
  const ys = points.map((p) => p.y)
  const xMin = Math.min(...xs)
  const yMin = Math.min(...ys)
  const xMax = Math.max(...xs)
  const yMax = Math.max(...ys)
  return { x: xMin, y: yMin, width: xMax - xMin, height: yMax - yMin }
}

export function fitRectIntoArea(rect, area, coverWholeArea = false) {
  const scaleStrategy = coverWholeArea ? Math.max : Math.min
  const scale = scaleStrategy(
    area.width / rect.width,
    area.height / rect.height
  )
  const x = rect.x - (area.width / scale - rect.width) / 2
  const y = rect.y - (area.height / scale - rect.height) / 2
  return { x, y, scale }
}

export const fitBoundsIntoArea = (rect, area, coverWholeArea = false) =>
  fitRectIntoArea(boundsToRect(rect), area, coverWholeArea)

export function transformRectCorners(matrix, width, height) {
  if (!matrix) {
    return []
  }
  const corners = [
    { x: 0, y: 0 },
    { x: 0, y: height },
    { x: width, y: 0 },
    { x: width, y: height },
  ]
  return corners.map((p) => {
    const { x, y } = matrix.transformPoint(p)
    return { x, y }
  })
}

export function applyPaddingToRectOrBounds(rect, padding) {
  return {
    x: rect.x - padding,
    y: rect.y - padding,
    width: rect.width + padding * 2,
    height: rect.height + padding * 2,
    left: rect.left - padding,
    top: rect.top - padding,
    bottom: rect.bottom + padding,
    right: rect.right + padding,
  }
}

export const clientToSvg = (point, view) => ({
  x: point.x / view.scale + view.x,
  y: point.y / view.scale + view.y,
})

export const correctClientPoint = (clientPoint, clientSize, zoom) => ({
  x:
    ((clientPoint.x / zoom - clientSize.left) /
      (clientSize.right - clientSize.left)) *
    clientSize.width,
  y:
    ((clientPoint.y / zoom - clientSize.top) /
      (clientSize.bottom - clientSize.top)) *
    clientSize.height,
})

export function nearestPow2(n) {
  return 2 ** Math.round(Math.log(n) / Math.log(2))
}
