import { useEffect, useState } from "react"
import { useInterval } from "react-use"

const serverPollInterval = 30000

export default function useServerOnline(request) {
  const [online, setOnline] = useState(true)

  async function pollServer() {
    try {
      const response = await fetch(request)
      setOnline(response.ok)
    } catch (err) {
      setOnline(false)
    }
  }

  useInterval(pollServer, serverPollInterval)

  useEffect(pollServer, [])

  return online
}
