import { useRef } from "react"
import useStoreScrollState from "../../../../hooks/useStoreScrollState"
import Raw from "../../../Raw"

export default function ColumnTextMain({ post }) {
  const {
    acf: { text },
  } = post

  const ref = useRef()
  const onScroll = useStoreScrollState(ref, "text")

  return (
    <div className="column-main" onScroll={onScroll} ref={ref}>
      <div className="inner">
        <Raw>{text}</Raw>
      </div>
    </div>
  )
}
