import { useMemo, useState } from "react"
import { Browser } from "./Browser"

import useFetch from "../../hooks/useFetch"
import MapContext from "./MapContext"
import MapWithMarkers from "./MapWithMarkers"
import parsePlaces from "./util/parsePlaces"
import Popup from "./Popup"

const URL_PLACES =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vS46UsEhbqH1-ae73OTSpXiIQ9fMf2jnDbc03d_iqUd5nJ5knEGnZJTUPJAuMODxUmzQizRyHGO0IG-/pubhtml?gid=0&single=true"

export default function Map() {
  const [browser, setBrowser] = useState(null)
  const [centeredMarkerId, setCenteredMarkerId] = useState(null)

  const [data] = useFetch(URL_PLACES, "text")
  const places = useMemo(() => parsePlaces(data), [data])
  const markers = places?.markers || []
  const categories = places?.categories || []

  const contextValue = useMemo(
    () => ({ setBrowser, setCenteredMarkerId, markers }),
    [setBrowser, setCenteredMarkerId, markers]
  )

  return (
    <MapContext.Provider value={contextValue}>
      <MapWithMarkers
        markers={markers}
        categories={categories}
        MarkerPopup={Popup}
        centeredMarkerId={centeredMarkerId}
      />
      {browser && (
        <Browser
          link={browser.link}
          title={browser.title}
          onHide={() => setBrowser(null)}
        />
      )}
    </MapContext.Provider>
  )
}
