import { useNavigate } from "react-router-dom"
import useGlobalState from "../../hooks/useGlobalState"
import Headlines from "./Headlines"
import Intro from "./Intro"

export default function Start({ category }) {
  const navigate = useNavigate()

  const { setState } = useGlobalState()

  const slug = "root-nav"
  const handleClick = () => {
    navigate(`/`)
    setState({ activeCategory: slug, scrollCategory: slug })
  }
  return (
    <>
      <Headlines />
      <Intro category={category}>
        <div className="arrow-button" onClick={handleClick} />
      </Intro>
    </>
  )
}
