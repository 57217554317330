import Raw from "../Raw"

export default function ColumnTitle({ post, footer, children }) {
  const { title, excerpt } = post

  return (
    <div className="column-title">
      <h2>
        <Raw>{title.rendered}</Raw>
      </h2>
      <div className="excerpt">
        <Raw>{excerpt.rendered}</Raw>
      </div>
      <div className="content">{children}</div>
      <div className="footer">{footer}</div>
    </div>
  )
}
