/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import { load } from "cheerio"

const defaultOptions = {
  useFirstRowForHeadings: false,
  stripHtmlFromHeadings: true,
  stripHtmlFromCells: true,
  stripHtml: null,
  forceIndexAsNumber: false,
  countDuplicateHeadings: true,
  ignoreColumns: null,
  onlyColumns: null,
  ignoreHiddenRows: true,
  headings: null,
  containsClasses: null,
  id: null,
  limitrows: null,
}

export default function convertHtmlTableToObject(html, optionsParameter = {}) {
  const options = {
    ...defaultOptions,
    ...optionsParameter,
  }

  if (options.stripHtml === true) {
    options.stripHtmlFromHeadings = true
    options.stripHtmlFromCells = true
  } else if (options.stripHtml === false) {
    options.stripHtmlFromHeadings = false
    options.stripHtmlFromCells = false
  }

  const jsonResponse = []
  let suffix

  const $ = load(html)

  let additionalSelectors = options.containsClasses
    ? `.${options.containsClasses.join(".")}`
    : ""
  additionalSelectors = options.id ? `${additionalSelectors}#${options.id}` : ""

  $(`table${additionalSelectors}`).each((_i, table) => {
    const tableAsJson = []
    const alreadySeen = {}
    // Get column headings

    // @fixme Doesn't support vertical column headings.
    // @todo Try to support badly formated tables.
    const columnHeadings = []

    let trs = $(table).find("tr")

    if (options.useFirstRowForHeadings) {
      trs = $(trs[0])
    }
    let headingsCounter = 0
    // Use headings for objects key evaluation
    trs.each((_index, row) => {
      const cells = options.useFirstRowForHeadings
        ? $(row).find("td, th")
        : $(row).find("th")
      cells.each((j, cell) => {
        if (options.onlyColumns && !options.onlyColumns.includes(j)) return
        if (
          options.ignoreColumns &&
          !options.onlyColumns &&
          options.ignoreColumns.includes(j)
        )
          return
        let value = ""

        if (options.headings) {
          value = options.headings[headingsCounter++]
        } else {
          const cheerioCell = $(cell)
          const cheerioCellText = cheerioCell.text()
          const cheerioCellHtml = cheerioCell.html()

          value = options.stripHtmlFromHeadings
            ? cheerioCellText.trim()
            : cheerioCellHtml
            ? cheerioCellHtml.trim()
            : ""
        }

        const seen = alreadySeen[value]
        if (seen && options.countDuplicateHeadings) {
          suffix = ++alreadySeen[value]
          columnHeadings[j] = value !== "" ? `${value}_${suffix}` : `${j}`
        } else {
          alreadySeen[value] = 1
          columnHeadings[j] = value
        }
      })
    })

    let rowspans = []

    // Fetch each row
    $(table)
      .find("tr")
      .each((i, row) => {
        const rowAsJson = {}

        function setColumn(j, content) {
          if (columnHeadings[j] && !options.forceIndexAsNumber) {
            rowAsJson[columnHeadings[j]] = content
          } else {
            rowAsJson[j] = content
          }
        }

        // Add content from rowspans
        rowspans.forEach((rowspan, index) => {
          if (!rowspan) return

          setColumn(index, rowspan.content)

          rowspan.value--
        })
        const nextrowspans = [...rowspans]

        const cells = options.useFirstRowForHeadings
          ? $(row).find("td, th")
          : $(row).find("td")
        cells.each((j, cell) => {
          // ignoreHiddenRows
          if (options.ignoreHiddenRows) {
            const style = $(row).attr("style")
            if (style) {
              const m = style.match(/.*display.*:.*none.*/g)
              if (m && m.length > 0) return
            }
          }

          // Apply rowspans offsets
          let aux = j
          j = 0
          do {
            while (rowspans[j]) j++
            while (aux && !rowspans[j]) {
              j++
              aux--
            }
          } while (aux)

          if (options.onlyColumns && !options.onlyColumns.includes(j)) return
          if (
            options.ignoreColumns &&
            !options.onlyColumns &&
            options.ignoreColumns.includes(j)
          )
            return

          const cheerioCell = $(cell)
          const cheerioCellText = cheerioCell.text()
          const cheerioCellHtml = cheerioCell.html()
          const cheerioCellRowspan = cheerioCell.attr("rowspan")

          const content = options.stripHtmlFromCells
            ? cheerioCellText.trim()
            : cheerioCellHtml
            ? cheerioCellHtml.trim()
            : ""

          setColumn(j, content)

          // Check rowspan
          const value = cheerioCellRowspan
            ? parseInt(cheerioCellRowspan, 10) - 1
            : 0
          if (value > 0) nextrowspans[j] = { content, value }
        })

        rowspans = nextrowspans
        rowspans.forEach((rowspan, index) => {
          if (rowspan && rowspan.value === 0) rowspans[index] = null
        })

        // Skip blank rows
        if (JSON.stringify(rowAsJson) !== "{}") tableAsJson.push(rowAsJson)

        if (options.limitrows && i === options.limitrows) {
          return false
        }
      })

    // Add the table to the response
    const dataContained = tableAsJson.length > 0
    const pushToJsonResult = Array.isArray(tableAsJson) && dataContained
    if (!pushToJsonResult) {
      return true
    }
    jsonResponse.push(tableAsJson)
  })

  return jsonResponse
}
