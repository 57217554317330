import Media from "../../../Media"
import SvgViewport from "../../../SvgViewport"

export default function ColumnGalleryMain({ media }) {
  const handleChangeView = (view) => {}

  const { width, height } = media?.media_details
  const contentSize = { x: 0, y: 0, width, height }

  const padding = Math.max(width, height) * 0.1
  return (
    <div className="column-main column-main-gallery">
      <SvgViewport
        initialViewbox={{
          x: -padding,
          y: -padding,
          width: width + padding * 2,
          height: height + padding * 2,
        }}
        onChangeView={handleChangeView}
        contentSize={contentSize}
      >
        <Media
          id={media.id}
          as="image"
          width={width}
          height={height}
          size="full"
        />
      </SvgViewport>
    </div>
  )
}
