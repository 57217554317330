import { useNavigate } from "react-router-dom"

import styles from "./CloseButton.module.scss"

export default function CloseButton({ onClick = null }) {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(-1)
  }
  return <div className={styles.closeButton} onClick={onClick || handleClick} />
}
