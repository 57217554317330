import { useEffect, useState } from "react"
import useLoadExternalJavascript from "./useLoadExternalJavascript"
import useLoadExternalStylesheet from "./useLoadExternalStylesheet"
import handleStyleImageMissing from "./util/handleStyleMissing"

const MAPBOX_ROOT = "https://api.mapbox.com/mapbox-gl-js/v2.1.1/"

// const sourceData = {
//   type: "geojson",
//   data: {
//     type: "Feature",
//     geometry: {
//       type: "Polygon",
//       coordinates: [
//         [
//           [13.418047, 52.475857],
//           [13.41809, 52.475491],
//           [13.387448, 52.474145],
//           [13.387405, 52.474511],
//         ],
//         [
//           [13.42015, 52.471649],
//           [13.42015, 52.47127],
//           [13.388947, 52.470497],
//           [13.388895, 52.470927],
//         ],
//       ],
//     },
//   },
// }

export default function useMap(options) {
  const urlStylesheet = `${MAPBOX_ROOT}mapbox-gl.css`
  useLoadExternalStylesheet(urlStylesheet)

  const [mapboxgl, setMapboxgl] = useState(null)
  const urlJavascript = `${MAPBOX_ROOT}mapbox-gl.js?access_token=${process.env.REACT_APP_MAP_TOKEN}`
  const handleLoadJavascript = () => setMapboxgl(window.mapboxgl)
  useLoadExternalJavascript(urlJavascript, handleLoadJavascript)

  const [map, setMap] = useState(null)
  useEffect(() => {
    if (!mapboxgl) {
      return
    }

    const nextMap = new mapboxgl.Map(options)
    nextMap.on("styleimagemissing", handleStyleImageMissing)

    // const handleMapLoad = (event) => {
    //   const sourceId = "runway"
    //   event.target.addSource(sourceId, sourceData)
    //   event.target.addLayer({
    //     id: "fill",
    //     type: "fill",
    //     source: sourceId,
    //     layout: {},
    //     paint: {
    //       "fill-color": "#0080ff",
    //       "fill-opacity": 0.5,
    //     },
    //   })
    //   event.target.addLayer({
    //     id: "outline",
    //     type: "line",
    //     source: sourceId,
    //     layout: {},
    //     paint: {
    //       "line-color": "#000",
    //       "line-width": 3,
    //     },
    //   })
    // }

    // nextMap.on("load", handleMapLoad)
    nextMap.once("styledata", () => setMap(nextMap))
  }, [mapboxgl, options])

  return map
}
