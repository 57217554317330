import { useEffect, useRef } from "react"
import { useAsync, useMap } from "react-use"
import { loadDocument } from "../../../util/pdf"
import ErrorMessage from "../../ErrorMessage"
import PdfContent from "./PdfContent"
import PdfContext from "./PdfContext"

export default function PdfViewer({ url, ...props }) {
  const pages = useAsync(async () => {
    if (!url) {
      return null
    }
    return loadDocument(url)
  }, [url])

  const [urlMap, { set: setUrl }] = useMap()

  const urlMapRef = useRef()
  urlMapRef.current = urlMap

  useEffect(
    () =>
      function cleanup() {
        Object.values(urlMapRef.current).forEach((generatedUrl) =>
          URL.revokeObjectURL(generatedUrl)
        )
      },
    []
  )

  if (!pages) {
    return null
  }

  return (
    <>
      {pages.loading && <div>Loading...</div>}
      {pages.error && <ErrorMessage>Error: {pages.error.message}</ErrorMessage>}
      {pages.value && (
        <PdfContext.Provider value={{ pages: pages.value, setUrl, urlMap }}>
          <PdfContent {...props} />
        </PdfContext.Provider>
      )}
    </>
  )
}
