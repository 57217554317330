import useGlobalState from "../hooks/useGlobalState"
import Img from "./Img"

export const sizes = {
  "1536x1536": "1536x1536",
  "2048x2048": "2048x2048",
  full: "full",
  medium: "medium",
  medium_large: "medium_large",
  thumbnail: "thumbnail",
}

export default function Media({ id, size = "medium", ...props }) {
  const {
    state: { media },
  } = useGlobalState()

  const object = media.find((item) => item.id === id)

  const src =
    (object?.media_details?.sizes || {})?.[size]?.source_url ||
    object?.source_url

  return <Img src={src} {...props} />
}
