import { useEvent } from "react-use"
import { useContext } from "react"
import { clientToSvg } from "../util/geometry"
import ZoomContext from "../comps/ZoomContext"

const zoomSensitivity = 0.0015

export default function useWheelZoom({
  view,
  setView,
  element,
  constrainView,
}) {
  const zoom = useContext(ZoomContext)

  const handleWheel = (event) => {
    event.preventDefault()

    const { deltaY, offsetX, offsetY } = event

    let factor = 1 - Math.abs(deltaY) * zoomSensitivity
    if (deltaY < 0) {
      factor = 1 / factor
    }

    const nextView = constrainView({
      ...view,
      scale: view.scale * factor,
    })

    const realOffsetX = offsetX / zoom
    const realOffsetY = offsetY / zoom

    const point = { x: realOffsetX, y: realOffsetY }
    const last = clientToSvg(point, view)
    const next = clientToSvg(point, { ...view, scale: nextView.scale })

    setView(
      constrainView({
        x: view.x - (next.x - last.x),
        y: view.y - (next.y - last.y),
        scale: nextView.scale,
      })
    )
  }

  useEvent("wheel", handleWheel, element, {
    passive: false,
  })
}
