export default function Pin({ color }) {
  return (
    <svg
      width="66"
      height="89"
      viewBox="0 0 66 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.3876 28.6718C61.3876 13.0113 48.6605 0.28418 33 0.28418C17.3394 0.28418 4.6123 13.0113 4.6123 28.6718C4.6123 49.9626 33 80.7159 33 80.7159C33 80.7159 61.3876 49.9626 61.3876 28.6718Z"
        fill={color}
      />
    </svg>
  )
}
