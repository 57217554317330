import { Component } from "react"
import ErrorMessage from "./ErrorMessage"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, errorMessage: null }
    this.handleClear = () => {
      console.log("handle clear")
      this.setState({ hasError: false, errorMessage: null })
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error({ error, errorInfo })
    this.setState({ hasError: true, errorMessage: error.message })
  }

  render() {
    const { hasError, errorMessage } = this.state
    const { children } = this.props
    if (hasError) {
      return (
        <ErrorMessage onClose={this.handleClear}>{errorMessage}</ErrorMessage>
      )
    }

    return children
  }
}

export default ErrorBoundary
