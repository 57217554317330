import { useEffect } from "react"

export default function useLoadExternalStylesheet(url, onLoad = null) {
  useEffect(() => {
    const link = document.createElement("link")
    link.href = url
    link.rel = "stylesheet"
    link.onload = onLoad
    document.head.appendChild(link)
    return () => link.remove()
  }, [url])
}
