import { useEffect } from "react"
import useGlobalState from "./useGlobalState"

export default function useStoreScrollState(ref, key) {
  const { state, setState } = useGlobalState()

  const onScroll = () => {
    const { scrollLeft, scrollTop } = ref.current
    setState({ scroll: { ...state.scroll, [key]: { scrollLeft, scrollTop } } })
  }

  useEffect(() => {
    const { scrollLeft, scrollTop } = state.scroll?.[key] || {}
    ref.current.scrollLeft = scrollLeft || 0
    ref.current.scrollTop = scrollTop || 0
  }, [state[key]])

  return onScroll
}
