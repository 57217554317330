export const absoluteUrl = (uri) => {
  const { protocol, host, pathname } = window.location
  return new URL(uri, `${protocol}//${host}${pathname}`).href
}

export const isElectron = () =>
  window.navigator.userAgent.toLowerCase().indexOf(" electron/") > -1

export const logDateTime = (dt = new Date()) => {
  const result = `${dt.getFullYear().toString().padStart(4, "0")}-${(
    dt.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")} ${dt
    .getHours()
    .toString()
    .padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}:${dt
    .getSeconds()
    .toString()
    .padStart(2, "0")}`

  return result
}
