import { useMeasure } from "react-use"
import ZoomContext from "./ZoomContext"

import styles from "./Layout.module.scss"
import NavBar from "./Menu/NavBar"
import useGlobalState from "../hooks/useGlobalState"

export default function Layout({ children }) {
  const [ref, actual] = useMeasure()

  const wanted = { width: 1920, height: 1080 }
  let zoom = Math.min(
    actual.width / wanted.width,
    actual.height / wanted.height
  )
  if (zoom > 1) {
    zoom = 1
  }

  const {
    state: { enlarged },
  } = useGlobalState()
  const fontSize = enlarged ? "22px" : "16px"

  return (
    <div className={styles.layout}>
      <div className="container" ref={ref} style={{ fontSize }}>
        <div className="inner" style={{ zoom }}>
          <ZoomContext.Provider value={zoom}>
            {children}
            <NavBar />
          </ZoomContext.Provider>
        </div>
      </div>
    </div>
  )
}
