import { clamp } from "lodash"
import { useEffect, useLayoutEffect, useState } from "react"
import useClientSize from "../hooks/useClientSize"
import useTouchZoom from "../hooks/useTouchZoom"
import useWheelZoom from "../hooks/useWheelZoom"
import { fitRectIntoArea } from "../util/geometry"
import styles from "./SvgViewport.module.scss"

const parseView = (view, size) =>
  `${view.x} ${view.y} ${size.width / view.scale} ${size.height / view.scale}`

const fitViewbox = (viewbox, size) => fitRectIntoArea(viewbox, size, false)

function restrictViewToContentArea(view, clientSize, contentSize) {
  // Zoom: minZoom fits all pages twice within the viewport. maxZoom is abitrarily
  // limited.
  const minScale =
    Math.max(
      clientSize.width / contentSize.width,
      clientSize.height / contentSize.height
    ) / 2
  const maxScale = 100

  // Pan: For each dimension the bounds are calculated indepently. The content-area
  // can move further to the edges, but not completely out of the viewport.
  const edge = 30 / view.scale
  const viewportWidth = clientSize.width / view.scale
  const viewportHeight = clientSize.height / view.scale
  const minPan = { x: -viewportWidth + edge, y: -viewportHeight + edge }
  const maxPan = { x: contentSize.width - edge, y: contentSize.height - edge }

  const scale = clamp(view.scale, minScale, maxScale)

  // Pan: For each dimension the bounds are calculated indepently. The content-area
  // can move further to the edges, but not completely out of the viewport.
  const nextView = {
    x: clamp(view.x, minPan.x, maxPan.x),
    y: clamp(view.y, minPan.y, maxPan.y),
    scale,
  }

  // Lean towards x-center when zooming out
  /*
  const nextViewportWidth = clientSize.width / scale
  if (nextViewportWidth > contentSize.width) {
    nextView.x = contentSize.width / 2 - nextViewportWidth / 2
  }
  */

  return nextView
}

export default function SvgViewport({
  children,
  initialViewbox = { x: 0, y: 0, width: 0, height: 0 },
  onChangeView,
  contentSize,
  setClientSize,
  view: viewFromProps,
  setView: setViewFromProps,
  scrollable = true,
  ...props
}) {
  const [ref, clientSize, element] = useClientSize()
  useEffect(() => {
    setClientSize?.(clientSize)
  }, [clientSize])

  const [viewFromState, setViewFromState] = useState(null)
  const view = viewFromProps || viewFromState
  const setView = setViewFromProps || setViewFromState

  useLayoutEffect(() => {
    if (!initialViewbox || !clientSize?.width || !clientSize?.height) {
      return
    }
    setView(fitViewbox(initialViewbox, clientSize))
  }, [initialViewbox, clientSize])

  const constrainView = (someView) =>
    restrictViewToContentArea(someView, clientSize, contentSize)
  useWheelZoom({
    view,
    setView,
    constrainView,
    clientSize,
    element: scrollable ? element : null,
  })
  const bind = useTouchZoom({ view, setView, constrainView, clientSize })

  const { x, y, scale } = view || {}

  useEffect(() => {
    const nextViewRect = {
      x,
      y,
      width: clientSize.width / scale,
      height: clientSize.height / scale,
    }
    onChangeView({ scale, viewRect: nextViewRect })
  }, [clientSize, scale, x, y, onChangeView])

  return (
    <svg
      {...(scrollable ? bind() : null)}
      className={styles.svg}
      ref={ref}
      viewBox={view ? parseView(view, clientSize) : null}
      {...props}
    >
      {children}

      {/* <circle fill="green" cx={debug.x} cy={debug.y} r="100" />
      <rect fill="none" stroke="yellow" strokeWidth={10} {...nextViewRect} /> */}
    </svg>
  )
}
