import CloseButton from "../CloseButton"
import styles from "../Post/Post.module.scss"
import TypeWebsite from "../Post/types/TypeWebsite/TypeWebsite"

export function Browser({ link, title, onHide }) {
  const post = {
    title: { rendered: title },
    excerpt: { rendered: "" },
  }

  return (
    <div className={styles.post} style={{ zIndex: 1000 }}>
      <div className="post-content post-content submenu-index-0">
        <TypeWebsite url={link} post={post} />
        <CloseButton onClick={onHide} />
      </div>
    </div>
  )
}
