import useMedia from "../../../hooks/useMedia"
import useObjectUrl from "../../../hooks/useObjectUrl"
import PdfViewer from "../PdfViewer/PdfViewer"

export default function TypePdf({ post }) {
  const mediaObject = useMedia(post?.acf?.pdf)
  const src = mediaObject?.source_url
  const url = useObjectUrl(src)
  if (!url) {
    return null
  }
  return <PdfViewer url={url} post={post} />
}
