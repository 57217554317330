import { useState } from "react"
import useMedia from "../../../../hooks/useMedia"
import ColumnGalleryMain from "./ColumnGalleryMain"
import ColumnGallerySide from "./ColumnGallerySide"
import ColumnGalleryTitle from "./ColumnGalleryTitle"

export default function TypeGallery({ post }) {
  const images = post?.acf?.images || []

  const [firstImage] = images
  const [image, setImage] = useState(firstImage)

  if (images.length === 0) {
    return null
  }

  const media = useMedia(image)
  if (!media) {
    return null
  }

  return (
    <>
      <ColumnGalleryTitle post={post} media={media} />
      <ColumnGalleryMain media={media} />
      {images.length > 1 && (
        <ColumnGallerySide images={images} image={image} setImage={setImage} />
      )}
    </>
  )
}
