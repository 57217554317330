import { useContext, useEffect, useState, useMemo } from "react"
import QRCode from "react-qr-code"
import { useEvent } from "react-use"
import { isElectron } from "../../../../util"
import ZoomContext from "../../../ZoomContext"
import useGlobalState from "../../../../hooks/useGlobalState"
import ColumnTitle from "../../ColumnTitle"
import CloseButton from "../../../CloseButton"
import styles from "./TypeWebsite.module.scss"

const allowedUrls = [
  "https://www.thf-berlin.de/de/standortinfos/standortgeschichte/",
  "https://www.thf-berlin.de/standortentwicklung/geschichtsgalerie/",
  "https://www.topographie.de/topographie-des-terrors/",
]

function WebContent({ url, webviewRef }) {
  const Component = isElectron() ? "webview" : "iframe"

  return <Component className="webview" src={url} ref={webviewRef} sandbox="" />
}

const cleanLinks = `(() => {
  const clearLinks = () => {
    document
      .querySelectorAll("a[href^='mailto:'],a[href^='tel:']")
      .forEach((a) => {
        a.removeAttribute("href")
      })
    document
      .querySelectorAll("a[target]")
      .forEach((a) => {
        a.removeAttribute("target")
      })
  }
  const callback = () => {
    console.log("callback")
    clearLinks()
  }
  clearLinks()
  const observer = new MutationObserver(callback)
  observer.observe(document.documentElement, {
    attributes: true,
    childList: true,
    subtree: true,
  })
})()`

const extractDomain = (url) => {
  const hostName = new URL(url).hostname
  const domain = hostName.split(".").slice(-2).join(".")
  return domain
}

function BlockedUrl({ url, onClose }) {
  return (
    <div className="blocked-url">
      <h2>Dieser Link ist nicht Teil der Ausstellung</h2>
      <WebsideQrCode url={url} />
      <CloseButton onClick={onClose} />
    </div>
  )
}

function ColumnWebsiteMain({ url, setUrl }) {
  const [buttonsEnabled, setButtonsEnabled] = useState({})

  const [webview, setWebview] = useState(null)
  const [didUpdate, setDidUpdate] = useState(false)
  const {
    state: { posts, enlarged },
  } = useGlobalState()

  const allowedDomains = useMemo(() => {
    const urls = posts
      .filter((post) => post.acf.type === "website")
      .map((post) => post.acf.website)
    return [...urls, ...allowedUrls].map(extractDomain)
  }, [posts])

  const [blockedUrl, setBlockedUrl] = useState(null)

  /*
  useLayoutEffect(() => {
    console.log("resize", rect)
    if (rect.width > 0 && rect.height > 0) {
      window.electron.message.send({ type: "resize", data: rect })
    }
  }, [rect])
  */

  const handleBack = () => webview.goBack()

  const handleForeward = () => webview.goForward()

  const handleRefresh = () => webview.reload()

  const handleUpdate = (event) => {
    const back = webview.canGoBack()
    const foreward = webview.canGoForward()
    setUrl(event.url)
    setButtonsEnabled({ back, foreward })
    webview.executeJavaScript(cleanLinks)
    setDidUpdate(true)
  }

  const handleWillNavigate = (event) => {
    console.log(event.url)
    const domain = extractDomain(event.url)
    const allow = allowedDomains.includes(domain)
    if (!allow) {
      webview.stop()
      setBlockedUrl(event.url)
    }
  }

  useEvent("did-navigate", handleUpdate, webview)
  useEvent("will-navigate", handleWillNavigate, webview)

  const zoom = useContext(ZoomContext)
  useEffect(() => {
    if (!webview || !didUpdate) return
    const zoomFactor = enlarged ? 1.5 : 1.2
    webview.setZoomFactor(zoom * zoomFactor)
  }, [webview, zoom, didUpdate, enlarged])

  return (
    <div className={`column-main-website ${styles.columnMainWebsite}`}>
      <div className="browser-bar">
        <div className="buttons">
          <button
            type="button"
            className="button-back"
            disabled={!buttonsEnabled.back}
            onClick={handleBack}
          />
          <button
            type="button"
            className="button-foreward"
            disabled={!buttonsEnabled.foreward}
            onClick={handleForeward}
          />

          <button
            type="button"
            className="button-refresh"
            onClick={handleRefresh}
          />
        </div>
        <div className="adress-bar">
          <span>{url}</span>
        </div>
      </div>
      <div className="window">
        <WebContent url={url} webviewRef={setWebview} />
        {blockedUrl && (
          <BlockedUrl url={blockedUrl} onClose={() => setBlockedUrl(null)} />
        )}
      </div>
    </div>
  )
}

function WebsideQrCode({ url, label = "Link öffnen" }) {
  return (
    <div className="qr-wrap">
      <p>{label}</p>
      <div className="qr-code">
        <QRCode size={192} value={url} bgColor="#ffffff" fgColor="#000000" />
      </div>
    </div>
  )
}

export default function TypeWebsite({ post, url: urlFromProps }) {
  const urlFromPost = post?.acf?.website
  const [url, setUrl] = useState(urlFromPost || urlFromProps)

  return (
    <>
      <ColumnTitle post={post} footer={<WebsideQrCode url={url} />} />
      <ColumnWebsiteMain url={url} setUrl={setUrl} />
    </>
  )
}
