import { useCallback, useContext, useMemo, useState } from "react"
import {
  clamp,
  nearestPow2,
  rectContainsOrIntersectsOtherRect,
} from "../../../util/geometry"
import { pdfViewerMaxScale, pdfViewerMinScale } from "../../../util/pdf"
import SvgViewport from "../../SvgViewport"
import Page from "./Page"
import PdfContext from "./PdfContext"
import styles from "./PdfViewer.module.scss"

export default function PdfViewport({
  fit = "all",
  scrollable,
  onChangeView,
  highlightRect,
  ...props
}) {
  let y = 0
  const padding = 100
  const { pages } = useContext(PdfContext)

  const tops = pages.map((page) => {
    const top = y
    y += page.height + padding
    return top
  })

  const contentHeight =
    pages.reduce((sum, page) => sum + page.height, 0) +
    padding * (pages.length - 1)

  const contentWidth = Math.max(...pages.map((page) => page.width))
  const contentSize = { x: 0, y: 0, width: contentWidth, height: contentHeight }

  const [clientSize, setClientSize] = useState(null)

  const initialViewbox = useMemo(() => {
    if (fit === "width") {
      if (!clientSize?.width) return null
      const scale = contentWidth / clientSize.width
      return {
        x: 0,
        y: 0,
        width: contentWidth,
        height: clientSize.height * scale,
      }
    }
    return contentSize
  }, [contentWidth, contentHeight, clientSize])

  const [scale, setScale] = useState(1)
  const quantizedScale = clamp(
    nearestPow2(scale),
    pdfViewerMinScale,
    pdfViewerMaxScale
  )
  const [viewRect, setViewRect] = useState(null)

  const handleChangeView = useCallback(
    (viewData) => {
      const { scale: nextScale, viewRect: nextViewRect } = viewData
      setViewRect(nextViewRect)
      setScale(nextScale)
      onChangeView?.(viewData)
    },
    [setViewRect, setScale, onChangeView]
  )

  return (
    <SvgViewport
      initialViewbox={initialViewbox}
      onChangeView={handleChangeView}
      setClientSize={setClientSize}
      contentSize={contentSize}
      scrollable={scrollable}
      {...props}
    >
      {pages.map((page, index) => {
        const top = tops[index]
        const left = (contentWidth - page.width) / 2
        const pageRect = {
          x: left,
          y: top,
          width: page.width,
          height: page.height,
        }
        if (
          viewRect &&
          !rectContainsOrIntersectsOtherRect(viewRect, pageRect)
        ) {
          return null
        }
        return (
          <g transform={`translate(${left}, ${top})`} key={page.id}>
            <rect width={page.width} height={page.height} fill="white" />
            <Page page={page} scale={quantizedScale} />
          </g>
        )
      })}

      {highlightRect && (
        <rect {...highlightRect} className={styles.highlightRect} />
      )}
    </SvgViewport>
  )
}
