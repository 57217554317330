import convertHtmlTableToObject from "./convertHtmlTableToObject"

/*
function parseTypo3Projects(projects) {
  if (!projects) {
    return {}
  }
  const markers = projects.map((project) => ({
    ...project,
    coordinates: [
      parseFloat(project.location.longitude),
      parseFloat(project.location.latitude),
    ],
  }))

  const categoriesObject = projects.reduce((acc, project) => {
    const { category } = project
    if (!acc[category.id]) {
      acc[category.id] = category
    }
    return acc
  }, {})

  const categories = Object.values(categoriesObject)

  return { markers, categories }
}
*/

function parseCoordinates(rawCoordinates) {
  const values = rawCoordinates.split(",").map((coord) => parseFloat(coord))
  if (values.length !== 2) {
    return null
  }

  const [longitude, latitude] = values.map((coord) => parseFloat(coord))
  if (!latitude || !longitude) {
    return null
  }

  return [latitude, longitude]
}

function parsePlace(place, id, categoriesArray) {
  const {
    Kategorie,
    Beschreibung,
    Koordinaten,
    Website,
    Bezeichnung,
    Icon,
    Geschichtspfad_Index,
  } = place

  const coordinates = parseCoordinates(Koordinaten)
  if (!coordinates) {
    return null
  }

  return {
    coordinates,
    title: Bezeichnung,
    id,
    description: Beschreibung,
    link: Website,
    icon: Icon,
    historyTrailIndex: Geschichtspfad_Index,
    category: {
      id: categoriesArray.indexOf(Kategorie),
      title: Kategorie,
    },
  }
}

const headerToObjects = (header, rows) => {
  const keys = Object.keys(header)
  return rows.map((row) =>
    keys.reduce(
      (acc, key) => ({
        ...acc,
        [header[key]]: row[key],
      }),
      {}
    )
  )
}

export default function parsePlaces(htmlText) {
  if (!htmlText) {
    return {}
  }

  const [allRows] = convertHtmlTableToObject(htmlText, {
    useFirstRowForHeadings: false,
    stripHtml: true,
  })

  const [header, ...rows] = allRows

  const rawPlaces = headerToObjects(header, rows)

  const allCategories = rawPlaces
    .map((place) => place.Kategorie)
    .filter(Boolean)

  const categoriesArray = [...new Set(allCategories)]
  categoriesArray.sort((a, b) => a - b)

  const categories = categoriesArray.map((title, id) => ({ id, title }))

  const markers = rawPlaces
    .map((place, index) => parsePlace(place, index, categoriesArray))
    .filter(Boolean)

  markers.sort((a, b) => {
    const [_latA, lonA] = a.coordinates
    const [_latB, lonB] = b.coordinates
    return lonB - lonA
  })

  return { markers, categories }
}
