import { useEffect, useRef } from "react"
import { useIntersection, usePrevious } from "react-use"
import useGlobalState from "../../hooks/useGlobalState"
import useTranslation from "../../hooks/useTranslation"
import PostLink from "./PostLink"
import Separator from "./Separator"

function SubMenuEntry({ item, index = 0 }) {
  const {
    setState,
    state: { scrollCategory, activeCategory },
  } = useGlobalState()
  const untranslatedCategory = item.object
  const ref = useRef()

  const category = useTranslation(untranslatedCategory, false)
  const { slug } = untranslatedCategory

  const intersection = useIntersection(ref, {
    rootMargin: "100px",
    threshold: 0.2,
  })
  const intersecting = intersection?.isIntersecting
  const lastIntersecting = usePrevious(intersecting)
  useEffect(() => {
    if (intersecting && !lastIntersecting) {
      if (scrollCategory === slug) {
        setState({ scrollCategory: null })
      } else if (!scrollCategory) {
        if (activeCategory !== slug) {
          setState({ activeCategory: slug })
        }
      }
    }
  }, [intersecting, lastIntersecting, scrollCategory, activeCategory])

  if (!category) {
    return null
  }

  const { level, children } = item
  const { name, description } = category

  return (
    <>
      <section
        className={`submenu submenu-index-${index} submenu-level-${level}`}
        id={`category-${slug}`}
        ref={ref}
      >
        <Separator />
        <div className="inner">
          <h1>{name}</h1>
          <p className="intro">{description}</p>
        </div>
        <div className={`children child-count-${children.length}`}>
          {children.map((child) => (
            <SubMenu key={child.id} item={child} />
          ))}
        </div>
      </section>
    </>
  )
}

export default function SubMenu({ item, index = 0 }) {
  const untranslatedCategory = item.object

  const category = useTranslation(untranslatedCategory, false)

  if (!category) {
    return null
  }

  const { level, children } = item

  if (level === 1) {
    return <SubMenuEntry index={index} item={item} />
  }

  if (level === 2 && children.length > 0) {
    return (
      <section className={`submenu submenu-level-${level}`}>
        <div className="inner">
          <h1>{category.name}</h1>
        </div>
        <div className={`children child-count-${children.length}`}>
          {children.map((child) => (
            <PostLink key={child.id} post={child.object} />
          ))}
        </div>
      </section>
    )
  }
  return null
}
