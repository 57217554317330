import { absoluteUrl } from "."

function parsePage(page, index) {
  const [x, y, w, h] = page.view
  const width = w - x
  const height = h - y
  const { rotate } = page
  const id = index
  const size =
    rotate === 90 || rotate === 270
      ? { width: height, height: width }
      : { width, height }

  return { id, page, ...size }
}

let pdfjsInstance

async function makePdfJs() {
  if (pdfjsInstance) {
    return pdfjsInstance
  }
  pdfjsInstance = await import("pdfjs-dist")
  pdfjsInstance.GlobalWorkerOptions.workerSrc = absoluteUrl(`pdf.worker.min.js`)
  return pdfjsInstance
}

export async function loadDocument(url) {
  const pdfjs = await makePdfJs()
  const pdf = await pdfjs.getDocument({
    url,
    cMapUrl: absoluteUrl(`cmaps/`),
    cMapPacked: true,
  }).promise
  const { numPages } = pdf
  const pageIndexes = Array.from(Array(numPages).keys())
  const promises = pageIndexes.map((index) => pdf.getPage(index + 1))
  const pages = await Promise.all(promises)
  return pages.map(parsePage)
}

export async function renderPage(page, scale) {
  const viewport = page.getViewport({ scale })
  const canvas = document.createElement("canvas")
  const canvasContext = canvas.getContext("2d")

  canvas.width = Math.floor(viewport.width * scale)
  canvas.height = Math.floor(viewport.height * scale)
  canvas.style.width = `${Math.floor(viewport.width)}px`
  canvas.style.height = `${Math.floor(viewport.height)}px`

  const transform = [scale, 0, 0, scale, 0, 0]

  const renderContext = {
    canvasContext,
    transform,
    viewport,
  }
  await page.render(renderContext).promise

  const blob = await new Promise((resolve) => canvas.toBlob(resolve))

  if (!blob) {
    return null
  }

  return URL.createObjectURL(blob)
}

export const pdfViewerMinScale = 0.5
export const pdfViewerMaxScale = 2
makePdfJs()
