import { useEffect, useRef } from "react"
import useGlobalState from "../../hooks/useGlobalState"
import useStoreScrollState from "../../hooks/useStoreScrollState"
import styles from "./Menu.module.scss"
import RootNav from "./RootNav"
import Separator from "./Separator"
import Start from "./Start"
import SubMenu from "./SubMenu"

export default function Menu() {
  const {
    state: { root, scrollCategory },
  } = useGlobalState()

  const ref = useRef()

  const handleStoreScroll = useStoreScrollState(ref, "menu")

  const handleWheel = (event) => {
    event.currentTarget.scrollLeft += event.deltaY
    handleStoreScroll()
  }

  useEffect(() => {
    if (!scrollCategory) {
      return
    }
    const node = document.querySelector(`#category-${scrollCategory}`)
    node?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    })
  }, [scrollCategory])

  return (
    <div
      className={styles.menu}
      onWheelCapture={handleWheel}
      ref={ref}
      onScroll={handleStoreScroll}
    >
      <section id="category-start">
        <Start category={root.object} />
      </section>
      <Separator />
      <section id="category-root-nav">
        <RootNav items={root.children} />
      </section>
      {root.children.map((item, index) => (
        <SubMenu key={item.id} index={index} item={item} />
      ))}
    </div>
  )
}
