import useGlobalState from "../../hooks/useGlobalState"
import CategoryLink from "./CategoryLink"
import CategoryLinkMap from "./CategoryLinkMap"
import styles from "./NavBar.module.scss"

const showLangToggle = false

export default function NavBar() {
  const {
    state: { root, lang, enlarged, online },
    setState,
  } = useGlobalState()
  const items = root.children
  const startCategory = { name: "Start", slug: "start" }

  const handleToggleLang = () => {
    const nextLang = lang === "de" ? "en" : "de"
    setState({ lang: nextLang })
  }

  const handleToggleEnlarged = () => {
    setState({ enlarged: !enlarged })
  }

  return (
    <div className={styles.navBar}>
      {showLangToggle && (
        <div className="toggle-lang" onClick={handleToggleLang}>
          <span>{lang === "de" ? "ENG" : "DEU"}</span>
        </div>
      )}
      <div
        className={`toggle-size ${enlarged ? "active" : ""}`}
        onClick={handleToggleEnlarged}
      >
        <span />
      </div>
      <CategoryLink category={startCategory} />
      {items.map((item, index) => (
        <div key={item.id} className={`submenu-index-${index}`}>
          <CategoryLink category={item.object} />
        </div>
      ))}

      {online && (
        <div className="submenu-map">
          <CategoryLinkMap />
        </div>
      )}
    </div>
  )
}
