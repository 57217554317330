import { useEffect, useState } from "react"
import { makeRequest, urlToFilename } from "../urls"
import useGlobalState from "./useGlobalState"

export default function useObjectUrl(src) {
  const {
    state: { online },
  } = useGlobalState()
  const [result, setResult] = useState(null)

  useEffect(() => {
    ;(async () => {
      if (!src) {
        return () => {}
      }
      const request = online ? makeRequest(src) : urlToFilename(src)
      const response = await fetch(request)
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)
      setResult(url)
      return function cleanup() {
        if (url) {
          URL.revokeObjectURL(url)
        }
      }
    })()
  }, [src, online])

  return result
}
