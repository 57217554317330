import React, { createContext } from "react"
import { useSetState } from "react-use"
import { defaultLang } from "../urls"

export const Context = createContext(null)

export default function ContextProvider({ children }) {
  const [state, setState] = useSetState({ lang: defaultLang })

  return (
    <Context.Provider value={{ state, setState }}>{children}</Context.Provider>
  )
}
