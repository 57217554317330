import categoryColorsByTitle from "./util/categoryColorsByTitle"

function Category({ id, title, activeCategoryId, onClick }) {
  const active = activeCategoryId === id
  const backgroundColor = categoryColorsByTitle[title]
  return (
    <div
      className={`category ${active ? "active" : ""}`}
      onClick={() => onClick(id)}
    >
      {backgroundColor && <div className="patch" style={{ backgroundColor }} />}
      {title}
    </div>
  )
}

export default function Categories({
  categories = [],
  setCategoryId,
  categoryId,
}) {
  return (
    <div className="column-side column-side-gallery">
      <h2>Kategorien</h2>
      <Category
        id={null}
        activeCategoryId={categoryId}
        onClick={setCategoryId}
        title="Alle Kategorien"
      />
      {categories.map((category) => (
        <Category
          key={category.id}
          {...category}
          activeCategoryId={categoryId}
          onClick={setCategoryId}
        />
      ))}
    </div>
  )
}
