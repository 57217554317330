import { useContext, useEffect } from "react"
import { pdfViewerMinScale, renderPage } from "../../../util/pdf"
import PdfContext from "./PdfContext"

const findProxy = (urlMap, page, wantedScale) => {
  let scale = wantedScale
  while (scale >= pdfViewerMinScale) {
    const key = `${page.id}_@${scale}`
    if (urlMap[key]) {
      return urlMap[key]
    }
    scale /= 2
  }
  return null
}

export default function Page({ page, scale }) {
  const { setUrl, urlMap } = useContext(PdfContext)
  const key = `${page.id}_@${scale}`
  const url = urlMap[key]
  const proxyUrl = findProxy(urlMap, page, scale)

  useEffect(() => {
    if (url) {
      return
    }
    ;(async () => {
      const nextUrl = await renderPage(page.page, scale)
      if (!nextUrl) {
        return
      }
      setUrl(key, nextUrl)
    })()
  }, [key, page.page, scale, setUrl, url])

  return (
    <image width={page.width} height={page.height} href={url || proxyUrl} />
  )
}
