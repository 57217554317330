export const findObject = (objects, id) =>
  objects.find((object) => object.id === id)

export const parseStructure = (menu, categories, posts) => {
  const parsePost = (post, level) => ({
    id: post.id,
    type: "post",
    object: post,
    children: [],
    level,
  })

  const parseMenu = (parent, level = 0) => {
    const type = parent.object
    const id = parseInt(parent.object_id, 10)
    const object = findObject(categories, id)

    let children
    if (level === 0 || level === 1) {
      children = menu
        .filter(
          (item) =>
            item.menu_item_parent === `${parent.ID}` &&
            item.object === "category"
        )
        .map((child) => parseMenu(child, level + 1))
    } else if (level === 2) {
      children = posts
        .filter((post) => post.categories.includes(id))
        .map((child) => parsePost(child, level + 1))
    } else {
      children = []
    }

    return {
      id,
      type,
      object,
      children,
      level,
    }
  }
  const rootItem = menu.find((item) => item.menu_item_parent === "0")

  return parseMenu(rootItem)
}
