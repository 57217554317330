import { isEqual } from "lodash"
import { useRef, useLayoutEffect, useMemo, useState } from "react"

export default function useClientSize(defaultState = { width: 0, height: 0 }) {
  const [element, ref] = useState(null)
  const [rect, setRect] = useState(defaultState)
  const rectRef = useRef()
  rectRef.current = rect

  const observer = useMemo(
    () =>
      new window.ResizeObserver(([entry]) => {
        if (!entry) {
          return
        }

        const { x, y, width, height, left, top, bottom, right } =
          entry.target.getBoundingClientRect()
        const nextRect = { x, y, width, height, left, top, bottom, right }
        if (!isEqual(nextRect, rectRef.current)) {
          setRect(nextRect)
        }
      }),
    []
  )

  useLayoutEffect(() => {
    if (!element || !observer) return undefined

    observer.observe(element)
    return () => {
      observer.disconnect()
    }
  }, [element, observer])

  return [ref, rect, element]
}
