import { useNavigate } from "react-router-dom"
import useGlobalState from "../../hooks/useGlobalState"
import useTranslation from "../../hooks/useTranslation"

export default function CategoryLink({ category: untranslatedCategory }) {
  const {
    setState,
    state: { activeCategory },
  } = useGlobalState()

  const navigate = useNavigate()

  const { slug } = untranslatedCategory

  const category = useTranslation(untranslatedCategory, false)

  if (!category) {
    return null
  }

  const { name } = category

  const handleClick = () => {
    navigate(`/`)
    setState({ activeCategory: slug, scrollCategory: slug })
  }
  return (
    <div
      className={`category-link category-${slug} ${
        activeCategory === slug ? "active" : ""
      }`}
      onClick={handleClick}
    >
      <span>{name}</span>
    </div>
  )
}
