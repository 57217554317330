import Raw from "../../../Raw"
import ColumnTitle from "../../ColumnTitle"

const parseTitle = (s) => {
  s = s.replaceAll("_", " ", s)
  s = s.replaceAll("(c)", " &copy; ", s)
  return s
}

export default function ColumnGalleryTitle({ post, media }) {
  return (
    <ColumnTitle
      post={post}
      footer={<Raw>{parseTitle(media.title?.rendered)}</Raw>}
    >
      <Raw>{parseTitle(media.caption?.rendered)}</Raw>
    </ColumnTitle>
  )
}
