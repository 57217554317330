const categoryColorsByTitle = {
  Gastronomie: "#FF300F",
  Geschichte: "#736562",
  "Kultur & Freizeit": "#EBC743",
  "Natur & Gärten": "#838B32",
  Service: "#fff",
  "Sport & Bewegung": "#CC164E",
  "Umwelt & Bildung": "#B2C18F",
}

export default categoryColorsByTitle
