/* eslint-disable no-restricted-globals */
export const wordpressHost = process.env.REACT_APP_WORDPRESS_HOST

export const wordpressMediaUrl = `${wordpressHost}/wp-json/wp/v2/media?_embed=true&per_page=1000`
export const wordpressCategoriesUrl = `${wordpressHost}/wp-json/wp/v2/categories?_embed=true&per_page=100`
export const wordpressPostsUrl = `${wordpressHost}/wp-json/wp/v2/posts?_embed=true&per_page=1000`
export const wordpressMenuUrl = `${wordpressHost}/wp-json/custom/menu`

export const makeRequest = (url) =>
  new Request(url, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Basic ${self.btoa(`gruen:kreuzberg`)}`,
    },
  })

const cacheFolder = "cache"

export const extractFilenameFromUrl = (url) =>
  new URL(url).pathname.split("/").pop()

export const urlToFilename = (url, extension = "") =>
  `./${cacheFolder}/${extractFilenameFromUrl(url)}${extension}`

export const defaultLang = "de"
