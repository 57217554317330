import { useEffect } from "react"
import { Route, Routes, useNavigate, useParams } from "react-router-dom"
import useGlobalState from "../../../../hooks/useGlobalState"
import useMedia from "../../../../hooks/useMedia"
import ColumnTitle from "../../ColumnTitle"
import ColumnGalleryMain from "../TypeGallery/ColumnGalleryMain"
import ColumnGallerySide from "../TypeGallery/ColumnGallerySide"
import ColumnGalleryTitle from "../TypeGallery/ColumnGalleryTitle"
import ColumnTextMain from "./ColumnTextMain"

function TextContent({ post, images }) {
  const navigate = useNavigate()
  const setImage = (id) => {
    navigate(`./image/${id}`)
  }

  return (
    <>
      <ColumnTitle post={post} />
      <ColumnTextMain post={post} />
      {images.length !== 0 && (
        <ColumnGallerySide images={images} setImage={setImage} />
      )}
    </>
  )
}

function TextContentImage({ post, images }) {
  const { image: imageString } = useParams()
  const image = parseInt(imageString, 10)
  const media = useMedia(image)

  const navigate = useNavigate()
  const setImage = (id) => {
    navigate(`/post/${post.slug}/image/${id}`, { replace: true })
  }

  return (
    <>
      <ColumnGalleryTitle post={post} media={media} />
      <ColumnGalleryMain media={media} />
      {images.length !== 0 && (
        <ColumnGallerySide images={images} image={image} setImage={setImage} />
      )}
    </>
  )
}

export default function TypeText({ post }) {
  const images = post?.acf?.images || []

  const { state, setState } = useGlobalState()
  useEffect(
    () => () => {
      setState({
        scroll: {
          ...state.scroll,
          text: { scrollLeft: 0, scrollTop: 0 },
        },
      })
    },
    []
  )

  return (
    <Routes>
      <Route index element={<TextContent post={post} images={images} />} />
      <Route
        path="image/:image"
        element={<TextContentImage post={post} images={images} />}
      />
    </Routes>
  )
}
