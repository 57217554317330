import { useMemo } from "react"
import { findObject } from "../util/menu"
import useGlobalState from "./useGlobalState"

const findTranslatedPostOrCategory = (
  item,
  items,
  lang,
  fallbackToGerman = true
) => {
  const { languages } = item
  if (!languages) {
    return item
  }
  if (languages.lang !== lang) {
    const otherId = languages.links[lang]
    const englishObject = findObject(items, otherId)
    const fallbackObject = fallbackToGerman ? item : null
    return otherId ? englishObject : fallbackObject
  }
  return item
}

export default function useTranslation(
  postOrCategory,
  fallbackToGerman = true
) {
  const {
    state: { lang, posts, categories },
  } = useGlobalState()
  const items = postOrCategory.type === "post" ? posts : categories
  const result = useMemo(
    () =>
      findTranslatedPostOrCategory(
        postOrCategory,
        items,
        lang,
        fallbackToGerman
      ),
    [lang, items, postOrCategory]
  )
  return result
}
