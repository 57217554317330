import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { useEvent, usePrevious, useTimeout } from "react-use"
import { logDateTime } from "../util"

const userIdleTime = 60000

function useUserIdle() {
  const [idle, setIdle] = useState(false)
  const [isReady, _, reset] = useTimeout(userIdleTime)

  const ready = isReady()
  useEffect(() => {
    if (!ready) {
      return
    }
    setIdle(true)
  }, [ready])

  const handleReset = () => {
    setIdle(false)
    reset()
  }

  useEvent("click", handleReset, window)
  useEvent("mousemove", handleReset, window)
  useEvent("scroll", handleReset, window)
  return idle
}

export default function useLocationStatistic() {
  const { pathname } = useLocation()
  const userIdle = useUserIdle()
  const location = userIdle ? "idle" : pathname
  const lastTimerRef = useRef(new Date().getTime())
  const lastLocation = usePrevious(location)

  useEffect(() => {
    if (lastLocation === "idle" || location === lastLocation || !lastLocation) {
      return
    }
    const nextTimer = new Date().getTime()
    const time = nextTimer - lastTimerRef.current
    lastTimerRef.current = nextTimer
    const seconds = Math.round(time / 1000)
    const dateTime = logDateTime()
    const logLine = [dateTime, lastLocation, seconds].join("\t")
    if (!window.electron) {
      return
    }
    window.electron.message.send(logLine)
  }, [location, lastLocation])
}
