import { useCallback, useContext, useState } from "react"
import { useEvent } from "react-use"
import ZoomContext from "../../ZoomContext"
import ColumnTitle from "../ColumnTitle"
import PdfContext from "./PdfContext"
import PdfViewport from "./PdfViewport"

const getOffsetPosition = (event) => {
  const { currentTarget, targetTouches } = event
  const bounds = currentTarget.getBoundingClientRect()
  if (targetTouches) {
    const [{ clientX, clientY }] = targetTouches
    return {
      x: clientX - bounds.x,
      y: clientY - bounds.y,
    }
  }
  const { clientX, clientY } = event
  return {
    x: clientX - bounds.x,
    y: clientY - bounds.y,
  }
}

export default function PdfContent({ post }) {
  const [view, setView] = useState(null)

  const { pages } = useContext(PdfContext)
  const singlePage = pages.length === 1

  const zoom = useContext(ZoomContext)

  const [viewData, setViewData] = useState(null)

  const [viewDataSidebar, setViewDataSidebar] = useState(null)

  const handleChangeView = useCallback(
    (nextViewData) => {
      setViewData(nextViewData)
    },
    [setViewData]
  )

  const handleChangeView2 = useCallback(
    (nextViewData) => {
      setViewDataSidebar(nextViewData)
    },
    [setViewDataSidebar]
  )

  const updateViewport = (event) => {
    const { y } = getOffsetPosition(event)

    if (!viewData) {
      return
    }

    const { viewRect } = viewData

    const { scale } = viewDataSidebar

    const nextY =
      y / scale / zoom + viewDataSidebar.viewRect.y - viewRect.height / 2

    const nextViewRect = {
      ...viewRect,
      y: nextY,
    }
    setViewData({ scale, viewRect: nextViewRect })
    setView({ ...view, y: nextY })
  }

  const [mouseDownActive, setMouseDownActive] = useState(null)

  const handlePointerDown = (event) => {
    setMouseDownActive(true)
    updateViewport(event)
  }

  const handlePointerMove = (event) => {
    if (!mouseDownActive) {
      return
    }
    updateViewport(event)
  }

  const handlePointerUp = () => {
    setMouseDownActive(false)
  }

  useEvent("mouseup", handlePointerUp)

  return (
    <>
      <ColumnTitle post={post} />
      <div className="column-main">
        <PdfViewport
          view={view}
          setView={setView}
          fit={singlePage ? "all" : "width"}
          onChangeView={handleChangeView}
        />
      </div>
      {!singlePage && viewData && (
        <div className="column-side">
          <PdfViewport
            onChangeView={handleChangeView2}
            scrollable={false}
            highlightRect={viewData.viewRect}
            onMouseDown={handlePointerDown}
            onMouseMove={handlePointerMove}
            onMouseUp={handlePointerUp}
            onTouchStart={handlePointerDown}
            onTouchMove={handlePointerMove}
            onTouchEnd={handlePointerUp}
          />
        </div>
      )}
    </>
  )
}
