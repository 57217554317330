import { useMemo } from "react"
import useGlobalState from "../../hooks/useGlobalState"
import CategoryLink from "./CategoryLink"
import PostLink from "./PostLink"

export default function RootNav({ items }) {
  const {
    state: { posts },
  } = useGlobalState()
  const imprintPost = useMemo(
    () => posts.find(({ slug }) => slug === "impressum"),
    [posts]
  )

  return (
    <div className="root-nav">
      {items.map((item, index) => (
        <div className={`submenu-index-${index}`} key={item.id}>
          <CategoryLink category={item.object} />
        </div>
      ))}
      <div className="imprint-link">
        {imprintPost && <PostLink post={imprintPost} />}
      </div>
    </div>
  )
}
