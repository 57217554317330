import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useTimeoutFn } from "react-use"
import CloseButton from "./CloseButton"
import styles from "./ErrorMessage.module.scss"

export default function ErrorMessage({ children, onClose = null }) {
  const navigate = useNavigate()
  const handleClick = useCallback(() => {
    navigate("/")
    onClose?.()
  }, [onClose, navigate])

  useTimeoutFn(handleClick, 5000)

  return (
    <>
      <div className={styles.errorMessage}>
        <p className="inner">
          <h1>Error</h1>
          <code>{children}</code>
        </p>
      </div>
      <CloseButton onClick={handleClick} />
    </>
  )
}
