import { useEffect, useMemo, useState } from "react"

import stylesPost from "../Post/Post.module.scss"
import styles from "./MapWithMarkers.module.scss"

import CloseButton from "../CloseButton"
import Categories from "./Categories"
import Marker from "./Marker"
import useMap from "./useMap"

const boundsX = 0.05
const boundsY = 0.02
const center = [13.403936, 52.474703]

export const mapOptions = {
  container: "map",
  style: "mapbox://styles/ehme/cldohgyha003y01seoc89cd7h",
  center,
  zoom: 14.5,
  language: "de",
  accessToken: process.env.REACT_APP_MAP_TOKEN,
  maxBounds: [
    [center[0] - boundsX, center[1] - boundsY],
    [center[0] + boundsX, center[1] + boundsY],
  ],
}

export default function MapWithMarkers({
  MarkerPopup,
  markers,
  categories,
  centeredMarkerId,
}) {
  const map = useMap(mapOptions)

  const [openId, setOpenId] = useState(null)
  const [categoryId, setCategoryIdInternal] = useState(null)

  useEffect(() => {
    const centeredMarker = markers.find(
      (marker) => marker.id === centeredMarkerId
    )
    if (!centeredMarker) {
      return
    }

    map.flyTo({
      center: centeredMarker.coordinates,
      essential: true,
    })
    setOpenId(centeredMarker.id)
  }, [centeredMarkerId])

  const setCategoryId = (id) => {
    setCategoryIdInternal(id)
    setOpenId(null)
  }

  const visibleMarkerIds = useMemo(
    () =>
      markers
        .filter(
          (marker) => marker.category.id === categoryId || categoryId === null
        )
        .map((marker) => marker.id),
    [categoryId, markers]
  )

  const handleClickMap = () => setOpenId(null)

  const markersReady = map && markers

  return (
    <div className={stylesPost.post}>
      <div className="post-content">
        <div className="column-main column-main-gallery">
          <div
            id={mapOptions.container}
            className={styles.map}
            onClick={handleClickMap}
          />

          {markersReady &&
            markers.map((marker) => (
              <Marker
                key={marker.id}
                map={map}
                marker={marker}
                open={openId === marker.id}
                onClick={() => setOpenId(marker.id)}
                onClose={() => setOpenId(null)}
                hide={!visibleMarkerIds.includes(marker.id)}
                Popup={MarkerPopup}
              />
            ))}
        </div>

        <div className={styles.categories}>
          <Categories
            categories={categories}
            setCategoryId={setCategoryId}
            categoryId={categoryId}
          />
        </div>
        <CloseButton />
      </div>
    </div>
  )
}
