import { useMemo } from "react"
import { Link } from "react-router-dom"
import useTranslation from "../../hooks/useTranslation"
import Media from "../Media"
import Raw from "../Raw"

export default function PostLink({ post: untranslatedPost }) {
  const post = useTranslation(untranslatedPost)
  const { slug } = untranslatedPost
  const { title, excerpt, featured_media } = post
  const rnd = useMemo(() => Math.random() * 0.3, [])

  const z = 100 * rnd
  const scale = 1 - rnd / 2

  return (
    <Link
      to={`/post/${slug}`}
      style={{
        transform: `translateZ(${z}px) scale(${scale})`,
      }}
    >
      <div className="text">
        <div className="title-wrap">
          <Raw>{title.rendered}</Raw>
        </div>
        <div className="excerpt-wrap">
          <Raw>{excerpt.rendered}</Raw>
        </div>
      </div>
      <Media id={featured_media} />
    </Link>
  )
}
