import { useEffect, useState } from "react"
import { HashRouter as Router, Route, Routes } from "react-router-dom"
import ErrorBoundary from "./comps/ErrorBoundary"
import GlobalContext from "./comps/GlobalContext"
import Layout from "./comps/Layout"
import Loader from "./comps/Loader"
import Menu from "./comps/Menu/Menu"
import Post from "./comps/Post"
import Map from "./comps/Map"
import NotFound from "./NotFound"
import { wordpressHost } from "./urls"

export default function App() {
  const [loaded, setLoaded] = useState(false)

  const handleWindowLoad = async () => {
    try {
      if (window.location.hostname !== wordpressHost) {
        const workerPath = "./service-worker.js"
        const registration = await navigator.serviceWorker.register(
          workerPath,
          {
            type: "module",
          }
        )
        console.log(
          "Service Worker registration successful with scope: ",
          registration.scope
        )
        setLoaded(true)
      }
    } catch (err) {
      console.log("Service Worker registration failed: ", err)
    }
  }

  useEffect(() => {
    // window.addEventListener("load", handleWindowLoad)
    setLoaded(true)
  }, [])

  if (!loaded) {
    return null
  }

  return (
    <GlobalContext>
      <Router>
        <Loader>
          <Layout>
            <ErrorBoundary>
              <Routes>
                <Route index element={<Menu />} />
                <Route path="post/:slug/*" element={<Post />} />
                <Route exact path="map" element={<Map />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </ErrorBoundary>
          </Layout>
        </Loader>
      </Router>
    </GlobalContext>
  )
}
