import { useNavigate } from "react-router-dom"
import useGlobalState from "../../hooks/useGlobalState"

export default function CategoryLinkMap() {
  const {
    setState,
    state: { activeCategory, lang },
  } = useGlobalState()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/map`)
    setState({ activeCategory: "map" })
  }

  return (
    <div
      className={`category-link category-map ${
        activeCategory === "map" ? "active" : ""
      }`}
      onClick={handleClick}
    >
      <span>{lang === "de" ? "Karte" : "Map"}</span>
    </div>
  )
}
