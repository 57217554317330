import { useEffect, useState } from "react"
import Pin from "./Pin"
import categoryColorsByTitle from "./util/categoryColorsByTitle"

export default function Marker({
  marker,
  map,
  open,
  onClick,
  onClose,
  hide,
  Popup,
}) {
  const [instance, setInstance] = useState(null)

  const handleRef = (el) => {
    if (!el || instance) {
      return
    }

    const { mapboxgl } = window
    const nextInstance = new mapboxgl.Marker(el)
    nextInstance.setLngLat(marker.coordinates)
    setInstance(nextInstance)
  }

  useEffect(() => {
    if (!instance) {
      return () => {}
    }
    if (hide) {
      instance.remove()
    } else {
      instance.addTo(map)
    }
    return () => instance.remove()
  }, [hide, instance, map])

  const {
    icon,
    category: { title: categoryTitle },
  } = marker

  const color = categoryColorsByTitle[categoryTitle]
  const backgroundImage = `url(./icons/${icon}.svg)`

  return (
    <div
      className={`marker ${open ? "open" : ""}`}
      ref={handleRef}
      onClick={onClick}
    >
      <Pin color={color} />
      <div className="icon" style={{ backgroundImage }} />
      {open && (
        <div className="info">
          <div className="close" onClickCapture={onClose} />
          <Popup {...marker} />
        </div>
      )}
    </div>
  )
}
