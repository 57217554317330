import { useEffect, useState } from "react"

export default function useFetch(url, mode = "json") {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const abortController = new AbortController()
    ;(async () => {
      try {
        const result = await fetch(url, {
          signal: abortController.signal,
        })
        const nextData = await result[mode]()
        if (abortController.signal.aborted) {
          return
        }
        setData(nextData)
      } catch (nextError) {
        setError(nextError)
      }
    })()
    return () => {
      abortController.abort()
    }
  }, [url, mode])

  return [data, error]
}
