import { useEffect } from "react"

export default function useLoadExternalJavascript(url, onLoad = null) {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = url
    script.onload = onLoad
    document.head.appendChild(script)
    return () => script.remove()
  }, [url])
}
